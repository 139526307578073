import { autoinject } from 'aurelia-dependency-injection';
import { NavigationInstruction, Next, Redirect } from 'aurelia-router';
import { AuthenticationProvider } from 'providers/authentication-provider';
import { CompaniesApiClient, CompanyLightweight } from 'services/cyber-api';
import { StateApi, StateKeys } from 'services/state-api';
import { LocalStorageHelper, LocalStorageKeys } from 'utilities/local-storage-helper';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EventKeys } from '../enums/event-keys';
import Swal from 'sweetalert2';
import { UpsellUtilities } from '../utilities/upsell-utilities';

declare var ga: any;

/**
 * The AuthorizeStep can be injected in Aurelia's RouterConfiguration
 * to be executed on each route request.
 */
@autoinject()
export class AuthorizeStep {
    constructor(
        private auth: AuthenticationProvider,
        private state: StateApi,
        private companiesApi: CompaniesApiClient,
        private eventAggregator: EventAggregator
    ) {
    }

    /**
     * Handles authentication for a navigation instruction.
     * @param navigationInstruction The navigation instruction to handle authentication for.
     */
    private async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
        this.setAuthReturnUrl(navigationInstruction);

        const allInstructions = navigationInstruction.getAllInstructions();

        // verify: if route (page) allows anonymous access, allow it without authenticating
        if (allInstructions.some((instruction) => instruction.config.settings && instruction.config.settings.allowAnonymous))
            return await next();

        // verify: if user is already authenticated, all is well
        if (this.auth.isAuthenticated()) {
            // Most test users may only have access to a fixed company, set it to enforce that company for the user
            const company = await this.setUserCompany(this.auth.profile.sub);

            // verify: if company is allowed to see this page
            const enableNavigation = (nav: NavigationInstruction): 'edrAd' | 'cyberAd' | 'utmAd' | 'none' => {
                const isEdr = nav.config.settings.isEdr;
                const edrActive = company.isEdrActive;
                const isThreatDetection = nav.config.settings.isThreatDetection;
                const threatDetectionActive = company.isThreatDetectionActive;
                const isUtm = nav.config.settings.isUtm;
                const utmActive = company.isUtmActive;

                if (isEdr && isThreatDetection) {
                    return edrActive || threatDetectionActive ? 'none' : 'cyberAd';
                } else if (isEdr) {
                    return !edrActive ? 'edrAd' : 'none';
                } else if (isThreatDetection) {
                    return !threatDetectionActive ? 'cyberAd' : 'none';
                } else if (isUtm) {
                    return !utmActive ? 'utmAd' : 'none';
                }
                return 'none';
            }

            const redirectToAd = enableNavigation(allInstructions.find(x => !x.config.hasChildRouter));
            if (redirectToAd != 'none') {
                if (redirectToAd === 'edrAd') {
                    return await next.cancel(UpsellUtilities.edrUpsell(allInstructions.find(x => !x.config.hasChildRouter)));
                } else if (redirectToAd === 'utmAd') {
                    return await next.cancel(UpsellUtilities.utmUpsell(allInstructions.find(x => !x.config.hasChildRouter)));
                } else {
                    return await next.cancel(UpsellUtilities.cyberUpsell(allInstructions.find(x => !x.config.hasChildRouter)));
                }
            }
            return await next();
        }

        // All other cases, simply return false
        return await next.cancel(this.auth.login());
    }

    /**
     * Sets the company based on the authenticated user's external id
     * @param externalId User to set company for
     */
    private async setUserCompany(externalId: string): Promise<CompanyLightweight> {
        const companyState = this.state.company();

        // Retrieve companies from the state cache
        let companies = this.state.companies() || [];

        // If state does not contain any companies, retrieve them from the API
        if (companies.length === 0)
            companies = await this.companiesApi.getAll(null);

        // Check if the user has access to the company in state, if so use that company
        let company = companies.find((c) => c.id === companyState);

        // If no company was found that matches the one previously selected and now in storage, use the default (first)
        if (!company) {
            // Check to see if the user has access to 'biz1000' and assign that,
            // otherwise - take first from the list the user has access to
            const b1000 = companies.find((c) => c.id.toLowerCase() === 'biz1000');
            company = b1000 || companies[0];
        }

        // Finally set the company states, for any subsequent page navigations
        this.state.setState(StateKeys.Company, company.id);
        this.state.setState(StateKeys.CompanyName, company.name);
        this.state.setState(StateKeys.Companies, companies);

        this.eventAggregator.publish(EventKeys.onCompanySet, company);

        return company;
    }

    private setAuthReturnUrl(navigationInstruction: NavigationInstruction): void {
        // Never store any of the auth URLs. Redirecting to these after login would cause infinite-loops and other
        // strange behaviour.
        if (navigationInstruction.config.name === 'login'
            || navigationInstruction.config.name === 'login-callback'
            || navigationInstruction.config.name === 'logout')
            return;

        let authReturnUrl = navigationInstruction.fragment;
        if (navigationInstruction.queryString)
            authReturnUrl = `${authReturnUrl}?${navigationInstruction.queryString}`;

        LocalStorageHelper.set<string>(LocalStorageKeys.AuthReturnUrl, authReturnUrl);
    }
}
