import { NavigationInstruction, Next, Redirect } from 'aurelia-router';

export class EnforceLowerCaseUrls {
    public async run(nav: NavigationInstruction, next: Next): Promise<any> {
        // see also:
        // https://stackoverflow.com/questions/48326901/enforce-lowercase-urls-in-aurelia-router/48337427#48337427
        if (/[A-Z]/.test(nav.fragment))
            return next.cancel(new Redirect(nav.fragment.toLowerCase()));
        else
            return next();
    }
}
