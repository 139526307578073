export class EventKeys {
    public static readonly onRefresh: string = 'OnRefresh';
    public static readonly onCyberNotificationReceived: string = 'OnCyberNotificationReceived';
    public static readonly onCyberNotificationRead: string = 'OnCyberNotificationRead';
    public static readonly onCyberNotificationsRead: string = 'OnCyberNotificationsRead';
    public static readonly onCompaniesChanged: string = 'OnCompaniesChanged';
    public static readonly onCompanyChanged: string = 'OnCompanyChanged';
    public static readonly onCompanyNameChanged: string = 'OnCompanyNameChanged';
    public static readonly onCompanySet: string = 'OnCompanySet';
    public static readonly beforeThreatAction: string = 'BeforeThreatAction';
    public static readonly onThreatAction: string = 'OnThreatAction';
    public static readonly beforeThreatsAction: string = 'BeforeThreatsAction';
    public static readonly onThreatsAction: string = 'OnThreatsAction';
    public static readonly onThreatCommentAdded: string = 'OnThreatCommentAdded';
    public static readonly onThreatAddedToCase: string = 'OnThreatAddToCase';
    public static readonly onTimeZoneChanged: string = 'OnTimeZoneChanged';
    public static readonly onPinSideMenuChanged: string = 'OnPinSideMenuChanged';
}
