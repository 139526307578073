declare var Headway: any;

export class HeadwayWidget {
    public static initialise(accountId: string): Promise<IHeadwayState> {
        const headwayConfig = {
            selector: '.changelog',
            account: accountId
        };
        return new Promise<IHeadwayState>((resolve) => {
            // As the changelog button has to be rendered first, and we don't know when exactly this happens, we can
            // wait for the button to be rendered using MutationObserver after which we initialise Headway
            const mutationObserver = new MutationObserver(mutations => {
                const headwayElement = document.querySelector(headwayConfig.selector);
                if (headwayElement) {
                    const headwayState = Headway.init(headwayConfig);
                    // Stop observing as the element required by Headway has been found
                    mutationObserver.disconnect();
                    // Resolve the promise and return the Headway state to the caller
                    resolve(headwayState);
                }
            });
            // Observe the header element for changes, as this is where the changelog button is
            const appHeaderElement = document.querySelector('.app-header');
            mutationObserver.observe(appHeaderElement, { childList: true, subtree: true });
        });
    }

    public static show(): void {
        Headway.show();
    }
}

export interface IHeadwayState {
    accountId: string;
    badgeEl: HTMLElement;
    badgeIsReady: boolean;
    changeLogs: any[];
    unseenCount: number;
    visible: boolean;
    widgetIsReady: boolean;
}
