import { AuthenticationProvider } from 'providers/authentication-provider';
import { Router } from 'aurelia-router';
import { LocalStorageHelper, LocalStorageKeys } from '../utilities/local-storage-helper';

export class FetchInterceptor {
    public static configure(authProvider: AuthenticationProvider, router: Router): void {
        // Override the Fetch API in order to intercept responses
        window.fetch = ((originalFetch) =>
            (input: RequestInfo, init?: RequestInit) =>
                // Execute the original fetch call
                originalFetch(input, init)
                    .then(async (response: Response) => {
                        // Check the result's status for 401 statuses, which should then force the user to login again
                        if (response.status === 401) {
                            // But if there are no companies defined in the profile redirect to an error page
                            if ((await response.text()).search('No companies defined in user profile') !== -1) {
                                LocalStorageHelper.remove(LocalStorageKeys.Companies);
                                router.navigateToRoute('error', {text: '<h2>It appears that your account has not been assigned permissions yet.</h2><br /> <a href="mailto:servicedesk@marlink.com">Please contact Marlink Service Desk</a>'});
                                return response;
                            }
                            authProvider.logout();
                        }
                        return response;
                    })
        )(fetch);
    }
}
