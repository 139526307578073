export class Value {

    /**
     * Check if a object exists
     * @param obj The object to validate
     */
    public static exists(obj: any): boolean {
        return obj !== undefined && obj !== null;
    }

    /**
     * Check if a object doesn't exists
     * @param obj The object to validate
     */
    public static doesNotExist(obj: any): boolean {
        return obj === undefined || obj === null || obj === '';
    }

    /**
     * Check if a string exists and is not empty
     * @param text The text to validate
     */
    public static notEmpty(text: string | number): boolean {
        return Value.exists(text) && text.toString() !== '';
    }

    /**
     * Check if a string exists and is empty
     * @param text The text to validate
     */
    public static isEmpty(text: string): boolean {
        return Value.exists(text) && text === '';
    }

    /**
     * Check if a number does not equals zero
     * @param nmbr The number to validate
     */
    public static notZero(nmbr: number): boolean {
        return Value.exists(nmbr) && nmbr !== 0;
    }

    /**
     * Check if a property/object is of type function
     * @param property The property to validate
     */
    public static isFunction(property: any): boolean {
        return Value.exists(property) && typeof property === 'function';
    }

    /**
     * Check if a property is valid according to the provided rules
     * @param rules The validation rules
     * @param property The property to validate
     */
    public static isValid(rules: RegExp, property: string): boolean {
        // tslint:disable-next-line:curly
        if (Value.doesNotExist(property) || Value.isEmpty(property)) return false;
        return property.search(rules) !== -1;
    }

    /**
     * Check if a text has the provided minimum length
     * @param length The minimum length
     * @param text The text to validate
     */
    public static hasMinLength(length: number, text: string): boolean {
        // tslint:disable-next-line:curly
        if (Value.doesNotExist(text)) return false;
        return text.length >= length;
    }
}
