declare let window: ISaberWindow;

export class SaberFeedback {
    public static initialise(): void {
        // Set Saver's API Key global, so the script can pick it up on initialisation
        window.saber_options = {
            api_key: 'a83278dd6ad919d6224f'
        };

        const currentDate = new Date();
        const currentDateString = '?' + currentDate.getFullYear() + (currentDate.getMonth() + 1) + currentDate.getDate() + currentDate.getHours();

        const scriptElement = document.createElement('script') as HTMLScriptElement;
        scriptElement.type = 'text/javascript';
        scriptElement.src = '//cdn.saberfeedback.com/feedback.js' + currentDateString;
        document.getElementsByTagName('head')[0].appendChild(scriptElement);
    }
}

// Extends the window interface to contain saber_options
interface ISaberWindow extends Window {
    saber_options: { api_key: string };
}
