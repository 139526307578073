import { Appender, Logger } from 'aurelia-logging';
import { RollbarService } from '../services/rollbar-service';

export class RollbarAppender implements Appender {
    constructor(
        private rollbarService: RollbarService
    ) { }

    public debug(logger: Logger, message, ...rest: any[]): void {
        // Do not send debug logs to Rollbar
    }

    public info(logger: Logger, message, ...rest: any[]): void {
        // Do not send info logs to Rollbar
    }

    public warn(logger: Logger, message, ...rest: any[]): void {
        this.rollbarService.rollbar.warning(`[${logger.id}] ${message}`, rest);
    }

    public error(logger: Logger, message, ...rest: any[]): void {
        this.rollbarService.rollbar.error(`[${logger.id}] ${message}`, rest);
    }
}
