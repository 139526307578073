import { PLATFORM } from 'aurelia-framework';

export class GlobalResources {
    public static resources: string[] = [
        /*
         * Root and Pre-Authentication
         */
        PLATFORM.moduleName('app'),

        /*
         * ValueConverters
         */
        PLATFORM.moduleName('converters/absolute-number-value-converter'),
        PLATFORM.moduleName('converters/action-steps-duration-value-converter'),
        PLATFORM.moduleName('converters/action-steps-target-value-converter'),
        PLATFORM.moduleName('converters/category-name-value-converter'),
        PLATFORM.moduleName('converters/counter-measure-action-status-value-converter'),
        PLATFORM.moduleName('converters/date-format-value-converter'),
        PLATFORM.moduleName('converters/difference-value-converter'),
        PLATFORM.moduleName('converters/duration-value-converter'),
        PLATFORM.moduleName('converters/id-to-company-value-converter'),
        PLATFORM.moduleName('converters/json-parse-value-converter'),
        PLATFORM.moduleName('converters/json-value-converter'),
        PLATFORM.moduleName('converters/last-in-array-value-converter'),
        PLATFORM.moduleName('converters/max-length-value-converter'),
        PLATFORM.moduleName('converters/number-value-converter'),
        PLATFORM.moduleName('converters/object-keys-value-converter'),
        PLATFORM.moduleName('converters/percentage-color-value-converter'),
        PLATFORM.moduleName('converters/percentage-value-converter'),
        PLATFORM.moduleName('converters/risk-category-value-converter'),
        PLATFORM.moduleName('converters/solution-link-value-converter'),
        PLATFORM.moduleName('converters/status-text-color-value-converter'),
        PLATFORM.moduleName('converters/threat-category-tooltip-value-converter'),
        PLATFORM.moduleName('converters/threat-status-color-value-converter'),
        PLATFORM.moduleName('converters/threat-status-name-value-converter'),
        PLATFORM.moduleName('converters/time-ago-value-converter'),
        PLATFORM.moduleName('converters/urgency-color-class-value-converter'),
        PLATFORM.moduleName('converters/urgency-display-value-converter'),
        PLATFORM.moduleName('converters/edr-containment-state-value-converter'),
        PLATFORM.moduleName('converters/channel-value-converter'),
        PLATFORM.moduleName('converters/last-action-value-converter'),
    ];
}
