import * as toastr from 'toastr';

export class ToastrConfiguration {
    private static readonly timeOut: number = 10000;

    public static configure(): void {
        toastr.options.timeOut = ToastrConfiguration.timeOut;
        toastr.options.extendedTimeOut = ToastrConfiguration.timeOut;
        toastr.options.closeButton = true;
        toastr.options.closeHtml = '<div><i class="fal fa-times"></i></div>';
    }
}
